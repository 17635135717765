@mixin limitLine {
    display: -webkit-box;
    -webkit-box-orient: vertical;  
    overflow: hidden;
  }
.container{
    height: auto;
    background-color: white;
    overflow: hidden;
    font-size: 0.75px;
  
}

.contHead{
    display: flex;
    justify-content: space-between;
}
.contNav{
    height: 75px;
    background-color: black;
}
.contHead h1{
    margin: 0px;
    margin-top: 38px;
    font-size: 50em;
    margin-left:  6.5%;
    margin-right: 30px;
   
}
.contBody{
    height: auto;
    display: flex;
    flex-wrap: wrap;
    margin-left: 5%;
    margin-right: 5%;
    padding-bottom: 75px;
   
}
.listCont{
    height: 60vh;
    border-style: solid;
    border-color: whitesmoke;
    border-width: 0.5px;
    width: 30%;
    margin: 1.5%;
   // background-color: blue;
    align-items: center;
    display: flex;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    //background-image: url('https://images.pexels.com/photos/5213545/pexels-photo-5213545.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500');
    box-shadow: 0 3px 7px 0 rgba(0,0,0,0.2);
    
}
.listCont2:hover{
    cursor: pointer;
    //transform: scale(1.02);
    background-color: rgba($color: #000, $alpha: 0.6)
    
}
.listCont2{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    //justify-content: space-between;
   
}

.dateCont{
    background-color: black;
    color: white;
    width: 45px;
    height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: flex-end;
    align-items: center;
    
}
.dateCont h1{
    font-weight: 700;
    line-height: 30px;
    margin: 0px;
    font-size: 30px;
}
.dateCont p{
    margin: 0px;
    font-size: 15px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    line-height: 20px;
    margin-top: 4px;
    
}
.listCont2:hover .placeCont{
   display: flex;
}
.placeCont{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 10%;
    padding-right: 10%;
    margin-top: 20%;
    display: none;
   
}
.placeCont h1{
 color: white;
 font-weight: 700;
 line-height: 45px;
 margin: 0px;
 font-size: 30px;
 text-align: center;
 -webkit-line-clamp: 4;
 @include limitLine();
}
.placeCont p{
    color: white;
    margin: 0px;
    font-size: 15px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    line-height: 20px;
    margin-top: 4px;
    text-align: center;
    margin-top: 8px;
    -webkit-line-clamp: 2;
    @include limitLine();
}
.footerCont{
    height: 100%;
    //background-color: black;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    display: none;
    
}
.footerCont2{
    height: 45px;
    background-color: black;
    display: flex;
}
@media all and (max-width: 900px) {
    .listCont{
        height: 300px;
        width: 31%;
        margin: 4px;
        
    }
    .placeCont h1{
        line-height: 30px;
        font-size: 21px;
       }
       .placeCont p{
           font-size: 15px;
           line-height: 19px;
       }
}
@media all and (max-width: 675px) {
    .contBody{
        margin-left: 23px;
        margin-right: 23px;
    }
    .contHead h1{
        margin-top: 15px;
        font-size: 27px;
        margin-left: 24px;
        margin-right: 0px;
    }
}
@media all and (max-width: 525px) {
    .listCont{
        height: 300px;
        width: 48%;
        margin: 4px;
        
    }
    .contHead h1{
        margin-top: 25px;
        font-size: 26px;
        margin-left: 24px;
        margin-right: 0px;
    }

}
@media all and (max-width: 450px) {
    .placeCont{
        margin-top: 1%;
    }
   
    .contBody{
        margin-left: 15px;
        margin-right: 15px;
        padding-bottom: 38px;
    }
    .listCont{
        height: 225px;
        width: 47%;
        margin: 5px;
        
    }
    .contHead h1{
        margin-top: 10px;
        font-size: 20px;
        margin-left: 10px;
        margin-right: 0px;
    }
    .dateCont{
        width: 30px;
        height:45px; 
    }
    .dateCont h1{
        font-weight: 700;
        line-height: 26px;
        font-size: 23px;
    }
    .dateCont p{
        font-size: 12px;
        line-height: 15px;
        margin-top: 0px;
        
    }
    .contNav{
        height: 75px;
    }
}
@media all and (max-width: 400px) {
    .contBody{
        margin-left: 10px;
        margin-right: 10px;
        padding-bottom: 38px;
    }
    .listCont{
        height: 225px;
        width: 47%;
        margin: 3px;
    }
    .dateCont h1{
        font-weight: 700;
        line-height: 26px;
        font-size: 20px;
    }
    .dateCont p{
        font-size: 12px;
        line-height: 15px;
        margin-top: 0px;
    }
    .placeCont h1{
        line-height: 23px;
        font-size: 18px;
       }
       .placeCont p{
           font-size: 14px;
           line-height: 19px;
       }

}
@media all and (max-width: 300px) {
   
    .contBody{
        margin-left: 8px;
        margin-right: 8px;
    }
    .listCont{
        height: 188px;
        width: 47%;
        margin: 3px;
        
    }
    .contHead h1{
        font-size: 17px;
    }
    .placeCont h1{
        line-height: 19px;
        font-size: 15px;
       }
       .placeCont p{
           font-size: 12px;
           line-height:15px;
       }
       
}















