$primaryColor: #fcaa17;
$hoverColor:#FBCD00;
@mixin limitLine {
    display: -webkit-box;
    -webkit-box-orient: vertical;  
    overflow: hidden;
  }
.container{
    height: auto;
    color: black;
    display: flex;
    flex-direction: column;
    padding-left: 6%;
    padding-right: 6%;
    padding-bottom: 70px;
}
.contNav{
    height: 75px;
    background-color: #000;
}
.contHead{
   // width: 100%;
    display: flex;
    justify-content: center;
    //font-size: 60px;
    background-color: white;
    flex-direction: column;
   
}
.contHeadTitles h1{
    font-size: 30px;
    margin: 0;
    margin-top: 30px;
    font-weight: 500;
    line-height: 25px;
    
}
.contHeadTitles h2{
    font-size: 30px;
    margin: 0;
    margin-top: 20px;
    font-weight: 500;
    line-height: 30px;
}
.contHeadTitles p{
    font-size: 16px;
    margin-top: 8px;
    line-height: 22px;
    font-family: 'Poppins', sans-serif;
    //font-family: 'Open Sans', sans-serif;
   
}
.galleryDiv{
    background-color: white;
}
.galleryDivH{
    font-size: 40px;
    margin: 0;
    margin-top: 30px;
    font-weight: 500;
    line-height: 30px;
    display: none;
   
}
.tit{
    font-size: 30px;
    margin: 0;
    padding-top: 30px;
    font-weight: 700;
    line-height: 35px;
}
.tickDiv{
    border: 1px solid black;
    width:auto;
    height: 40px;
    display: flex;
    align-items: center;
    margin-top: 20px;
    margin-right: 20px;
    padding-right: 20px;
    
}
.tickDiv:hover{
    background-color: black;
    color: white;
    cursor: pointer;
}
.subTit{
    font-size: 30px;
    font-weight: 500;
    border-radius: 10px;
    margin-left: 5px;
    padding-top: 3px;
}

.tickIc{
    font-size: 30px;
    border-right: 1px solid;
}
.artListDiv{
    display: flex;
    flex-wrap: wrap;
}
.galDiv{
    display: flex;
    border-bottom: 1px solid whitesmoke;
    margin-top: 30px;
}
.galDiv h2{
    font-size: 30px;
    font-weight: 500;
    margin-right: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px 10px 0px 0px;
    padding: 5px 20px 2px 20px;
    //line-height:25px;
   
}
.galDiv h2:hover{
    cursor: pointer;
    background-color: black;
    color: white;
}
.videosDiv{
    height: 50px;
    color:white;
    width:150px;
    background-color: black;
    border-radius: 20px 20px 0px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.achieveUl li{
    font-family: 'Poppins', sans-serif;
    text-align: left;
    margin: 5px;
    font-weight: 400;
    font-size: 14px;
  }
  .div1{
      display: flex;
      margin-top: 30px;
      height: 400px;
      overflow: hidden;
  }
  .artDetDiv{
      margin-left: 30px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex:1;
      
  }
  .artImgDiv{
    //flex:1;
    height:100%;
    object-fit:cover;
    display: flex;
    align-items: center;
    //justify-content: center;
    overflow: hidden;
    border-bottom: 1px solid whitesmoke;
    position: relative;
  }
  #artImgDiv2{
      display: none;
  }
  .artImgDiv img{
    height: 100%;
  }
  .divEarly2{
  display: none;
  }
  .divEarly1{
    display: block;
  }
  .who1Div{
    display: none;
}
  @media all and (max-width: 1200px) {
    .divEarly2{
        display: block;
        }
        .divEarly1{
          display: none;
        }
  }
@media all and (max-width: 900px) {

    .contHeadTitles h1{
        margin-top: 30px;
        font-size: 30px;
    }
    .contHeadTitles p{
        font-size: 14px;
        margin-top: 6px;
        line-height: 20px;
    }
   
     .galleryDivH{
        font-size: 30px;
        margin-top:23px; 
        padding-left:23px;
        padding-right:23px;
    }
    
}
@media all and (max-width: 800px) {
    .div1{
        margin-top: 30px;
        height: 300px;
    }
      .artImgDiv img{
        height: 80%;
      }
}
@media all and (max-width: 700px) {
    .div1{
        margin-top: -20px;
        height: 500px;
        flex-direction: column;
        flex-direction: center;
        width: 100%;
        align-items: center;
    }
    #artImgDiv1{
        display: none;
    }
    #artImgDiv2{
        display: flex;
    }
    .artImgDiv{
        //display: none;
        height: 500px;
        width: 100%;
    }
    .artImgDiv img{
        width: 100%;
        object-fit: cover;
        
      }
    .artDetDiv{
        margin-left: 0px;
        display: none;
    }
    .who1Div{
        display: block;
     
    }
}
@media all and (max-width: 600px) {
   
    .galDiv h2{
        font-size: 25px;
        font-weight: 500;
        margin-right: 2px;
        padding: 5px 20px 2px 20px;
    }
////////////////////////////////
    .tit{
        font-size: 35px;
        padding-top: 20px;
        font-weight: 500;
        line-height: 30px;
    }
    .tickDiv{
        height: 40px;
        margin-top: 10px;
        margin-right: 10px;
        
    }
    .subTit{
        font-size: 20px;
        font-weight: 500;
        border-radius: 10px;
        margin-left: 5px;
        padding-top: 3px;
    }
    
    .tickIc{
        font-size: 25px;
        border-right: 1px solid;
    }
    /////////////////////////////////
}
@media all and (max-width: 500px) {
    .galDiv h2{
        font-size: 20px;
        font-weight: 500;
        margin-right: 2px;
        padding: 5px 15px 2px 15px;
    }
}

@media all and (max-width: 420px) {
    .div1{
        margin-top: -20px;
        height: 370px;
       
    }
    .artImgDiv{
        height: 370px;
        width: 100%;
    }
   
    .contHeadTitles h1{
        font-size: 28px;
        line-height: 26px;
    }
   
    .galleryDivH{
        font-size: 23px;
        margin-top: 15px; 
        padding-left: 8px;
        padding-right: 8px;
    }
    .container{
        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 20px;
    }
    .galDiv h2{
        font-size: 18px;
        font-weight: 500;
        margin-right: 2px;
        padding: 5px 15px 2px 15px;
    }
    .achieveUl{
        margin-left: -20px;
      }
}
@media all and (max-width: 350px) {

    .contHeadTitles h1{
       margin-top: 25px;
    }
    .div1{
        margin-top: -20px;
        height: 350px;
    }
    .artImgDiv{
        height: 350px;
        width: 100%;
        
    }
}
@media all and (max-width: 320px) {
    .galDiv h2{
        font-size: 16px;
        font-weight: 500;
        margin-right: 2px;
        padding: 5px 15px 2px 15px;
    }
    .div1{
        margin-top: -20px;
        height: 320px;
    }
    .artImgDiv{
        height: 320px;
        width: 100%;
    }
}
