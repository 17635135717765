.container{
    height: auto;
    background-color: whitesmoke;
    display: flex;
    box-sizing: border-box;
    padding-top: 0px;
    padding-bottom: 70px;
    margin-top: 20px;
    //font-size: 1px;
}
.container1{
 flex: 1;
 display: flex;
 //background-color: red;
 justify-content: flex-end;
 align-items: center;
 overflow: hidden;
 //margin-bottom: 50px;
}
.container2{
    box-sizing: border-box;
    flex: 1;
    display: flex;
    //background-color: rgb(58, 58, 36);
    flex-direction: column;
    justify-content: center;
    padding-left: 75px;
    padding-right: 75px;
    overflow: hidden;
}
.container2 h2{
    font-family: 'Oswald', sans-serif;
    font-weight: 700;
    font-size: 60px;
    color: black;
    line-height: 68px;
    margin: 0px;
   
}
.container2 p{
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    margin-top: 15px;
}
.contImg{
    height: 75vh;
    min-height: 590px;
    width: 80%;
    background-color: whitesmoke;
    overflow: hidden;
}
.artImg{
    width: 100%;
    height: 100%;
    max-height: 100%;
    max-width: 100%;
    object-fit:cover;
    overflow:hidden;
}
.logosDiv{
    display: flex;
    flex-wrap: wrap;
    //background-color: red;
}
.boomImg{
    height: 65px;
    width: 180px;
    margin-top: 16px;
   // margin-right: 15px;
}
.googleImg{
    height: 67px;
    width: 190px;
    margin-top:15px;
   // margin-right: 15px;
    //margin-left: 0px;
}
.appImg{
    height: 58px;
    width: 180px;
    margin-top: 18px;
}

.appImg:hover{
    cursor: pointer;
    transform: scale(1.05);
}
.googleImg:hover{
    cursor: pointer;
    transform: scale(1.05);
}
.boomImg:hover{
    cursor: pointer;
    transform: scale(1.05);
}
.contTitle{
    display: none;
}

@media all and (max-width: 1050px) {
  
    .appImg{
        height: 58px;
        width: 180px;
        margin-top: 18px;
    }

    .container2{
        padding-left: 38px;
       // padding-left: 38px;
        padding-right: 38px;
    }
    .container2 h2{
        font-weight: 700;
        font-size: 35px;
        line-height: 53px;
      
    }
    .container2 p{
        font-size: 15px;
        line-height: 20px;
        margin-top: 15px;
    }

    .contImg{
        height: 450px;
        min-height: auto;
        width: 100%;
        background-color: whitesmoke;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .artImg{
        width: 80%;
        height: 100%;
        max-height: 100%;
        max-width: 100%;
        object-fit:cover;
        overflow:hidden;
    }

}

@media all and (max-width: 900px) {
    .contImg{
        height: 435px;
        width: 100%;
        background-color: whitesmoke;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 38px;
    }
    .artImg{
        width: 100%;
        height: 100%;
        max-height: 100%;
        max-width: 100%;
        object-fit:cover;
        overflow:hidden;
    }


    .logosDiv{
        display: flex;
        flex-wrap: wrap;
        //background-color: red;
    }
    .boomImg{
        height: 55px;
        width: 150px;
        margin-top: 16px;
       // margin-right: 15px;
    }
    .googleImg{
        height: 58px;
        width: 160px;
        margin-top: 14px;
       // margin-right: 15px;
        //margin-left: 0px;
    }
    .appImg{
        height: 48px;
        width: 150px;
        margin-top: 10px;
    }
}
@media all and (max-width: 750px) {
    .boomImg{
        height: 45px;
        width: 130px;
        margin-top: 16px;
       // margin-right: 15px;
    }
    .googleImg{
        height: 47px;
        width: 140px;
        margin-top:15px;
       // margin-right: 15px;
        //margin-left: 0px;
    }
    .appImg{
        height: 40px;
        width: 130px;
        margin-top: 10px;
    }
}

@media all and (max-width: 675px) {
    .container{
       flex-direction: column;
       height: auto;
    }  
    .container1{
        flex: 1;
        justify-content: center;
       
       }
       .container2{
      width: 90%;
      align-items: center;
      //background-color: yellow;
      align-self: center;
      margin-top: 38px;
       
       }
    .contImg{
        height: 375px;
        width: 80%;
        background-color: whitesmoke;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 0px;
        margin-top: 0px;
    }
    .logosDiv{
        width: 100%;
        display: flex;
        margin-top: 20px;
    }
    .artImg{
        width: 100%;
        height: 100%;
        max-height: 100%;
        max-width: 100%;
        object-fit:cover;
        overflow:hidden;
    }
    .boomImg{
    height: 55px;
    width: 130px;
    margin-top: 12px;
    margin-right: 10px;
}
.appImg{
    height: 54px;
    width: 130px;
    margin-top: 12px;
    margin-left: 15px;
}
.googleImg{
    height: 59px;
    width: 130px;
    margin-top:10px;
   
}
.container2 h2{
    font-weight: 700;
    font-size: 41px;
    line-height: 49px;
  
}


}
@media all and (max-width: 563px) {
    .contImg{
        height: 413px;
        width: 85%;
        margin-top: 25px;
    }
    .container2 h2{
        font-weight: 700;
        font-size: 38px;
        line-height: 45px;
    }
    .container2{
        padding-left: 23px;
        padding-right: 23px;
    }
    .container{
        height: auto;
        background-color: whitesmoke;
        display: flex;
        box-sizing: border-box;
        padding-top: 0px;
        padding-bottom: 53px;
        //font-size: 1px;
    }
    .contTitle{
        font-size: 25px;
        line-height: 30px;
        margin-top: 10px;
    }
}
@media all and (max-width: 450px) {
    .logosDiv{
        margin-top: 0px;
    }
    .container2{
        padding-left: 0px;
        padding-right: 0px;
    
    }
.container2 h2{
    font-weight: 700;
    font-size: 35px;
    line-height: 40px;
}
.contImg{
    height: 375px;
    width: 95%;
}
.contTitle{
    display: block;
    font-weight: 700;
    //font-size: 38px;
    //line-height: 45px;
    margin-left: 15px;
}
.container{
    height: auto;
    padding-bottom: 30px;
   
}
.boomImg{
    height: 43px;
    width: 100px;
    margin-top: 12px;
    margin-right: 5px;
}
.appImg{
    height: 41px;
    width: 100px;
    margin-top: 14px;
    margin-left: 5px;
}
.googleImg{
    height: 45px;
    width: 100px;
    margin-top:12px;
   
}
}
@media all and (max-width: 375px) {
    .container2{
        padding-left: 0px;
        padding-right: 0px;
        margin-top: 23px;
    
    }
.container2 h2{
    font-weight: 700;
    font-size: 30px;
    line-height: 35px;
}
.contImg{
    height: 300px;
    width: 95%;
}
.contTitle{
    font-size: 25px;
    line-height: 30px;
    margin-top: 0px;
}
.contImg{
    margin-top: 15px;
}
.container{
    height: auto;
    padding-bottom: 15px;
   
}

.boomImg{
    height: 33px;
    width: 80px;
    margin-top: 12px;
    margin-right: 5px;
}
.appImg{
    height: 32px;
    width: 80px;
    margin-top: 13px;
    margin-left: 5px;
}
.googleImg{
    height: 35px;
    width: 80px;
    margin-top:12px;
   
}
}
@media all and (max-width: 300px) {
    .logosDiv{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .container2{
        padding-left: 0px;
        padding-right: 0px;
    
    }
.container2 h2{
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
}
.container2 p{
    font-size: 14px;
    line-height: 19px;
    margin-top: 8px;
}
.contImg{
    height: 225px;
    width: 95%;
}
.contTitle{
    font-size: 23px;
    line-height: 30px;
    margin-left: 8px;
}
.contImg{
    margin-top: 8px;
}


.boomImg{
    height: 40px;
    width: 100%;
    min-width: 110px;
    margin-top: 12px;
    margin-right: 5px;
}
.appImg{
    height:40px;
    width: 100%;
    min-width: 110px;
    margin-top: 5px;
    margin-left: 0px;
}
.googleImg{
    height: 40px;
    width: 100%;
    min-width: 110px;
    margin-top:10px;
   
}
}