.container{
    height: 150px;
    background-color: black;
    margin-bottom: 38px;
    display: flex;
    align-items: center;
    padding-left: 10%;
    padding-right: 10%;
    justify-content: space-between;
}
.container h1{
    color: white;
    margin: 0px;
    font-size: 38px;
}
.container p{
    color: white;
    margin: 0px;
    border-color: white;
    border-style: solid;
    border-width: 2px;
    padding: 8px 23px;
    font-size: 19px;
    font-weight: 600;

}
.container p:hover{
    cursor: pointer;
    background-color: #3f3f3f;
}
@media all and (max-width: 750px) {

    .container h1{
        color: white;
        margin: 0px;
        font-size: 30px;
    }
    .container p{
        color: white;
        margin: 0px;
        border-color: white;
        border-style: solid;
        border-width: 2px;
        padding: 8px 23px;
        font-size: 19px;
        font-weight: 600;
    
    }
}

@media all and (max-width: 525px) {

    .container h1{
        color: white;
        margin: 0px;
        font-size: 26px;
    }
    .container p{
        color: white;
        margin: 0px;
        border-color: white;
        border-style: solid;
        border-width: 2px;
        padding: 8px 23px;
        font-size: 15px;
        font-weight: 600;
    
    }
}

@media all and (max-width: 450px) {

    .container{
        padding-left: 15px;
        padding-right: 15px; 
    }
    .container h1{
        color: white;
        margin: 0px;
        font-size: 19px;
    }
    .container p{
        color: white;
        margin: 0px;
        border-color: white;
        border-style: solid;
        border-width: 2px;
        padding: 8px 15px;
        font-size: 14px;
        font-weight: 600;
    
    }
}
@media all and (max-width: 300px) {
    .container{
        height: 113px;
        padding-left: 11px;
        padding-right: 11px; 
    }
    .container h1{
        color: white;
        margin: 0px;
        font-size: 15px;
    }
    .container p{
        color: white;
        margin: 0px;
        border-color: white;
        border-style: solid;
        border-width: 2px;
        padding: 6px 8px;
        font-size: 1px;
        font-weight: 600;
    
    }
}
