.contFooter{
    width: 100%;
    height: 230px;
    background-color: black;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: hidden;
  
}
.contFooter2{
    display: flex;
    width: 100%;
    justify-content: center;
}
.contFooter h1{
    font-size: 38px;
    margin-top: 23px;
    margin-bottom: 4vh;
}
.contFooter h3{
 font-size: 15px;
 font-family: 'Open Sans', sans-serif;
 margin-top: 4vh;
}
.footerIcons{
    font-size: 38px;
    margin-left: 15px;
    margin-right: 20px;
    color: #fff;
}
.footerIcons:hover{
    cursor: pointer;
    color: #5612d4;
}

@media all and (max-width: 525px) {
    .contFooter h1{
        font-size: 30px;
        margin-top: 23px;
        margin-bottom: 15px;
    }
    .contFooter{
        height: 200px;
        overflow: hidden;
      
    }
    .footerIcons{
        font-size: 30px;
        margin-left: 11px;
        margin-right: 11px;
    }

}


@media all and (max-width: 375px) {
    .contFooter h1{
        font-size: 23px;
        margin-top: 23px;
        margin-bottom: 15px;
    }
    .contFooter{
        height: 170px;
        overflow: hidden;
      
    }
    .footerIcons{
        font-size: 23px;
       
    }
    .contFooter h3{
        font-size: 13px;
        font-family: 'Open Sans', sans-serif;
        margin-top: 4vh;
       }

}