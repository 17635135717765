$primaryColor: #fcaa17;
@mixin limitLine {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;  
   overflow: hidden;
}
.container{
  height: auto;
  width: 100%;
  position: relative;
  
}
.albumDetails{
  height: auto;
  margin-top: 15px;
  display: flex;
  min-height: 375px;
  margin-left: 8px;
  margin-right: 8px;
}
.albumArtWork{
  width: 40%;
  overflow: hidden;
  //display: flex;
  //background-color: whitesmoke;
 }
 .albumImg{
   width: 95%;
   height: 95%;
   max-height: 100%;
   max-width: 100%;
   object-fit:cover;
   overflow:hidden;
 }
 .albumAvail{
   display: flex;
   margin-top: 8px;
 }
 .albumAvail a{
  color: #000;
  margin-right: 20px;
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;
 }
 .albumAvail a:hover{
  color: rgb(108, 35, 202);
 }
  .albumDet h3{
    font-size: 20px;
    margin-right: 15px;
    text-decoration: underline;
    font-weight: 500;
   
  }
  .albumDet h3:hover{
   cursor: pointer;
   color: $primaryColor;
  }
 
 .albumDet{
   width: 60%;
   //background-color: red;
 }
 .albumDet h2{
   font-size: 35px;
   font-weight: 500;
 }
 .title1{
  font-weight: 700;
  margin-right: 8px;
  font-size: 16px;
  line-height: 20px;
  font-family: 'Open Sans', sans-serif;
  
}
.title2{
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  font-family: 'Open Sans', sans-serif;
  }
  .title{
    //font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    font-family: 'Open Sans', sans-serif;
    margin-bottom: 19px;
    margin-top: 0px;
  }
  .titleP{
    margin-top: 5px;
  }
  #availTxt{
    margin-top: 8px;
    font-size: 30px;
    font-weight: 500;
  }
  .songsH3{
    margin-top: 20px;
    font-size: 40px;
   line-height: 23px;
   margin-left: 8px;
   margin-bottom: 15px;
   font-weight: 500;
  }
.listCont{
  display: flex;
  flex-wrap: wrap;
  //justify-content: space-between;
}
.divList{
  height: auto;
  width: 23.5%;
  margin-right: 15px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  margin-top: 10px;
  overflow: hidden;
  padding-bottom: 10px;
  cursor: pointer;
}
.divImg{
  height: 280px;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content:center;
}
.divList img{
  height: 100%;
  background-size: cover;
  

}
.artName{
  font-family: 'Rubik', sans-serif;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 7px;
  font-weight: 600;
  -webkit-line-clamp: 1;
  @include limitLine();
}
.songDet{
  font-family: 'Rubik', sans-serif;
  padding-left: 10px;
  padding-right: 10px;
  -webkit-line-clamp: 1;
  @include limitLine();
}
.songDur{
  font-family: 'Rubik', sans-serif;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 12px;
  font-weight: 600;
  color: $primaryColor;
  padding-top: 3px;
  -webkit-line-clamp: 1;
  @include limitLine();
}
@media all and (max-width: 1200px) {
  .divList{
    width: 31.8%;
    margin-right: 10px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    margin-top: 10px;
    overflow: hidden;
  }
}


@media all and (max-width: 800px) {

  .albumDetails{
    height: auto;
    margin-top: 15px;
    display: flex;
    flex-wrap: wrap;
    min-height: 375px;
    margin-left: 8px;
    margin-right: 8px;
    flex-direction: column;
  }
  .albumArtWork{
    overflow: hidden;
    height: 400px;
    width: 100%;
    align-items: center;
    justify-content: center;
   }
   .albumImg{
    width: 100%;
    max-height: 100%;
  }
  .albumDet{
    width: 100%;
  }
  .divList{
    width: 31.5%;
    margin-right: 10px;
  }
}
@media all and (max-width: 700px) {
  .divImg{
    height: 220px;
    width: 100%;
   
  }
  .divList{
    width: 47%;
    margin-right: 10px;
    margin-top: 10px;
  }
}
@media all and (max-width: 500px) {

  .divImg{
    height: 180px;
    width: 100%;
   
  }
  .divList img{
    width: 100%;
    height: auto;
  
  }
  .albumDet h2{
    font-size:20px;
  }
  #availTxt{
    font-size:20px;
  }
  .songsH3{
    font-size:20px;
  }
  .albumAvail a{
    margin-right:10px;
    font-size: 16px;
   }
}
@media all and (max-width: 420px) {
  .divList{
    width: 100%;
    margin-right: 0px;
    margin-top: 10px;
  }
  .divImg{
    height: 250px;
    width: 100%;
  }
  .title{
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 10px;
    margin-top: 0px;
  }
  .title1{
    margin-right: 8px;
    font-size: 14px;
    line-height: 15px;
  }
  .title2{
    font-size: 14px;
    line-height: 15px;
    }
    .albumArtWork{
      height: 350px;
      width: 100%;
     }
     .albumAvail a{
      margin-right:10px;
      font-size: 12px;
     }
}




