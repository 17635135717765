.container{
    height: auto;
    background-color: black;
    display: flex;
    padding-top: 15px;
    padding-bottom: 45px;
    
}

.container1{
    flex: 1;
    display: flex;
    //background-color: red;
    flex-direction: column;
   
   }
   .container2{
       box-sizing: border-box;
       flex: 1;
       display: flex;
       //background-color:yellow;
       padding-left: 38px;
       padding-right: 38px;
       flex-direction: column;
       padding-top: 23px;
       padding-bottom: 15px;
       position: relative;
       
   }

   .listCont{
       display: flex;
       //flex: 1;
       height: 53px;
       justify-content: space-between;
       margin-top: 15px;
       //align-items: center;
       //background-color: red;
   }
   .listContTime{
       display: flex;
       //align-items: center;
       //justify-content: center;
      
   }
   .listCont h1{
       margin: 0px;
       font-weight: 700;
       font-size:26px;
       line-height: 30px;
       color: white;
   }
   .playIcon{
      margin-top: -5px;
      margin-left: 23px;
      color: white;
      font-size: 23px;
      margin-top: 4px;
   }
   .playIcon:hover{
       cursor: pointer;
       color: rgb(106, 13, 255);
       transform: scale(1.05);
   }
   .albumWorkDiv{
       //background-color: yellow;
       height: 60vh;
       width: 60%;
       align-self: center;
       margin-top: 38px;
   }
   .container1 h2{
       font-size: 38px;
       font-weight: 700;
       line-height: 45px;
       color: white;
       margin: 0;
       margin-top: 38px;
       margin-left: 20%;
       margin-right: 10%;
   }
   .container1 p{
    font-size: 15px;
    font-weight: 300;
    line-height: 20px;
    color: white;
    font-family: 'Open Sans', sans-serif;
    margin-left: 20%;
    margin-right: 10%;
    margin-top: 15px;
}
.artImg{
    width: 100%;
    height: 100%;
    max-height: 100%;
    max-width: 100%;
    object-fit:cover;
    overflow:hidden;
}



.platCont{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 15px;
    
}
.platList{
  width: 30%;
  height: 53px;
  border-color: #13bba4;
  border-style: solid;
  border-width: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
 
}
.platList:hover{
    cursor: pointer;
    //transform: scale(1.05);
    //background-color: #13bba4;
}
.platList:hover p{
    transform: scale(1.1);
}
.platList p{
    font-size: 15px;
    font-weight: 700;  
    color: #13bba4;
}


.notReleasedSnack{
    overflow: hidden;
    box-sizing: border-box;
    height: auto;
    width: 375px;
    min-height: 188px;
    background-color: rgb(36, 36, 36);
    position: absolute;
    align-self: center;
    margin-top: 10%;
    border-radius: 15px;
    box-shadow: 0px 0 10px rgba(0, 0, 0, 0.1);
    padding: 15px;
    font-family: 'Poppins', sans-serif;
    display: flex;
    flex-direction: column;
    z-index: 1;
}
.notReleasedSnack h3{
    text-align: center;
    font-size: 19px;
    margin-top: -8px;
    margin-bottom: 4px;
}
.notReleasedSnack h4{
    text-align: center;
    font-size: 15px;
    color: white;
}
.songTitle{
    color: #13bba4;
}
.closeRelease{
    font-size: 30px;
    color: white;
    align-self: flex-end;
}
.closeRelease:hover{
    transform: scale(1.1);
    cursor: pointer;
    color: #13bba4;
   }
@media all and (max-width: 1050px) {
   
    .albumWorkDiv{
        height: 50vh;
        width: 100%;
        background-color: black;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
    .artImg{
        width: 90%;
        height: 100%;
        max-height: 100%;
        max-width: 100%;
        object-fit:cover;
        overflow:hidden;
    }
    .container1 h2{
        font-size: 30px;
        font-weight: 700;
        line-height: 38px;
        color: white;
        margin: 0;
        margin-top: 38px;
        margin-left: 15px;
        margin-right: 15px;
    }
    .container1 p{
     font-size: 15px;
     font-weight: 300;
     line-height: 19px;
     margin-left: 23px;
     margin-right: 23px;
     margin-top: 15px;
 }
 .listCont h1{
    margin: 0px;
    font-weight: 700;
    font-size:19px;
    line-height: 30px;
    color: white;
}
.playIcon{
   margin-top: 5px;
   margin-left: 23px;
   color: white;
   font-size: 19px;
}
.container{
    height: auto;
    background-color: black;
    display: flex;
    padding-top: 15px;
    padding-bottom: 30px;
}
}

@media all and (max-width: 675px) {
    .listCont h1{
        margin: 0px;
        font-weight: 700;
        font-size:17px;
        line-height: 30px;
        color: white;
    }
    .playIcon{
       //margin-top: -5px;
       margin-left: 23px;
       color: white;
       font-size: 17px;
    }
  

}
@media all and (max-width: 600px) {
    .container{
        flex-direction: column;
        padding-bottom: 15px;
    }
    
    .listCont h1{
        font-size:19px;
        line-height: 26px;
        
    }
    .playIcon{
       margin-left: 23px;
       font-size: 19px;
       margin-top: 3px;
    }
    .container1 h2{
        font-size: 30px;
        margin-top: 38px;
        margin-left: 38px;
        margin-right: 23px;
    }
    .container1 p{
     font-size: 15px;
     line-height: 19px;
     margin-left: 38px;
     margin-right: 23px;
     margin-top: 15px;
 }
 .albumWorkDiv{
    height: 50vh;
    justify-content: center;
}
.artImg{
    width: 88%;
}
.listCont{
    height: 53px;
    margin-top: 0px;
}

}
@media all and (max-width: 375px) {
    .listCont h1{
        font-size:17px;
        line-height: 23px;
        
    }
    .playIcon{
       margin-left: 23px;
       font-size: 17px;
    }
    .container1 h2{
        font-size: 23px;
        margin-top: 23px;
        margin-left: 23px;
        margin-right: 23px;
        line-height: 26px;
    }
    .container1 p{
     font-size: 14px;
     line-height: 19px;
     margin-left: 23px;
     margin-right: 23px;
     margin-top: 15px;
 }
 .albumWorkDiv{
    height: 45vh;
    justify-content: center;
}
.artImg{
    width: 88%;
}
.container2{
    padding-left: 23px;
    padding-right: 23px;
    flex-direction: column;
    padding-top: 15px;
    padding-bottom: 15px;
}
.container{
    margin-top: 15px;
}

.notReleasedSnack{
   // height: 250px;
    width: 300px;
    margin-top: 15%;
    padding: 8px;
}
.platList p{
    font-size: 12px;
    font-weight: 700;  
    color: #13bba4;
}
.platList{
    height: 38px;
    margin-bottom: 8px;
  }
  .notReleasedSnack h3{
    font-size: 15px;
    margin-top: -8px;
    margin-bottom: 4px;
}
.notReleasedSnack h4{
    font-size: 12px;
}
.closeRelease{
    font-size: 26px;
}
}

@media all and (max-width: 300px) {
    .container{

        padding-bottom: 0px;
    }
    .listCont h1{
        font-size:15px;
        line-height: 19px;
        
    }
    .playIcon{
       margin-left: 23px;
       font-size: 17px;
    }
    .container1 h2{
        font-size: 19px;
        margin-top: 23px;
        margin-left: 15px;
        margin-right: 15px;
        line-height: 27px;
    }
    .container1 p{
     font-size: 14px;
     line-height: 17px;
     margin-left: 15px;
     margin-right: 15px;
     margin-top: 15px;
 }
 .albumWorkDiv{
    height: 40vh;
    justify-content: center;
}
.artImg{
    width: 88%;
}
.container2{
    padding-left: 15px;
    padding-right: 15px;
}
.listCont{
    height: 45px;
    margin-top: 0px;
}
.notReleasedSnack{
   // height: 200px;
    width: 225px;
    margin-top: 15%;
}
.platList p{
    font-size: 9px;
    font-weight: 700;  
    color: #13bba4;
}
.platList{
    height: 30px;
    margin-bottom: 8px;
  }
  .notReleasedSnack h3{
    font-size: 14px;
    margin-top: -8px;
    margin-bottom: 4px;
}
.notReleasedSnack h4{
    font-size: 14px;
}
.closeRelease{
    font-size: 23px;
}
}