$primaryColor: #FFBD00;
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');

/*kim*/
/* width */
::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  /*kim*/


.nav{
    width: 100%;
   // background-color: black;
    background-color: rgba($color: #fff, $alpha: 0);
    //background-color:rgba(0, 0, 0, 0.1);
    transition: 0.25s;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: auto;
    z-index: 9;
    position: absolute;
    //overflow: hidden;
    justify-content: center; 
    font-size: 0.75px;
    overflow-x: hidden;
}


.scrolled{
    position: fixed;
    background-color:black;
    z-index: 9;
   
}
.navCont{
    //background-color: yellow;
    display: flex;
    justify-content: space-between;
    //flex: 1;
    width: 95%;
    align-items: center;
    height: 75px;
    padding-left: 38px;
    padding-right: 38px;
   
    //position: absolute;
}
.navCont2{
    background-color: black;
}
.nav h1{
  margin: 0px;
  color: white;
  font-size: 45em;
  line-height: 30px;
 
}
.nav h1:hover{
    //border-bottom-color: white;
    //border-bottom-width: 2px;
    //border-bottom-style: solid;
   // padding-bottom: 5px;
    cursor: pointer;
    transform: scale(1.05);
   
  }
.linkUl{
    display: flex;
    text-decoration: none;
    list-style: none;
    color: white;
    padding-right: 38px;
   
}
.linkUl li{
    margin-right: 38px;
    font-size: 25em;
    font-weight: 700;
    letter-spacing: 1px;
   
}
.linkUl li:hover{
    cursor: pointer;
    color: #7213cc;
}
.navImg{
    width: 200px;
}
.ulLink{
    text-decoration: none;
    color: white;
}
.linkUl2{
    //display: flex;
   // flex-direction: column;
    text-decoration: none;
    list-style: none;
    color: white;
    display: none;
    background-color: red;
    //width: 100%;
    height: auto;
    padding-bottom: 23px;
   // flex: 1;
   // justify-content: center;
   
}
.openOptions{
    font-size: 30px;
    color: white;
    display: none;
    padding-right: 38px;
}
.closeOptions{
    font-size: 38px;
    color: white;
    display: none;
    padding-right: 38px;
}

@media all and (max-width: 1275px) {
    .navCont{
         padding-right: 0%;
     }
}

@media all and (max-width: 1165px) {
    
    .linkUl li{
        margin-right: 15px;
        font-size: 25em;
        
    }
}
@media all and (max-width: 975px) {
    .linkUl li{
        margin-right: 15px;
        font-size: 23em;
        
    }
}
@media all and (max-width: 940px) {
    .linkUl li{
        margin-right: 15px;
        font-size: 20em;
        
    }
    .nav h1{
        font-size: 40em;
      }
}
@media all and (max-width: 863px) {
    .linkUl{
        display: none;
    }
    .openOptions{
        display: block;
    }
    .openOptions:hover{
        cursor: pointer;
    }
    .closeOptions{
        display: block;
    }
    .closeOptions:hover{
        cursor: pointer;
    }
    .linkUl2{
        display: block;
        background-color: black;
    }
    .linkUl2 li{
        font-size: 25em;
        font-weight: 700;
        padding-top: 15px;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 10px;
        //text-align: center;
       
    }
    .menuItems:hover{
        background-color: #292929;
       
    }
    .menuItems{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .menuArrow{
        color: white;
        font-size: 40em;
        padding-right: 20px;
       
    }
    .nav{
        width: 100%;
    }
}

@media all and (max-width: 420px) {
  
    .navCont{
        box-sizing: border-box;
        width: 100%;
        height: 75px;
        padding-left: 10px;
        padding-right:20px;
    }
    .nav h1{
        font-size: 30em;
        padding-left: 0px;
      }
      .openOptions{
        font-size: 30px;
        padding-right: 0px;
    }
    .closeOptions{
        font-size: 30px;
        padding-right: 0px;
    }
    .navImg{
        width: 150px;
    }

}

@media all and (max-width: 300px) {
  
    .navCont{
        box-sizing: border-box;
        width: 100%;
        height: 53px;
        padding-left: 15px;
        padding-right:15px;
       
    }
    .nav h1{
        font-size: 25em;
        padding-left: 0px;
      }
      .openOptions{
        font-size: 25em;
        padding-right: 0px;
    }
    .closeOptions{
        font-size: 25em;
        padding-right: 0px;
    }
  
    .linkUl2 li{
        font-size: 25em;
        padding-left: 23px;
        padding-right: 0px;
       
    }
    .menuArrow{
        font-size: 30em;
        padding-right: 15px;
       
    }
    .navImg{
        width: 100px;
    }

}



/*@media all and (max-width: 600px) {
   
    .navCont{
        height: 70px;
        padding-left: 20px;
        padding-right: 0px;
       
       
    }
    .nav h1{
        margin: 0px;
        color: white;
        font-size: 20em;
        line-height: 40px;
       }
       .openOptions{
        font-size: 25px;
        
    }
    .closeOptions{
        font-size: 30px;
        
    }
     
}*/




