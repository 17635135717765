* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  //height: 0.75;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  
}
body{
  margin: 0px;
  padding: 0px;
  width: 100%;
 
  //overflow-x: hidden;
}
.appCont{
  min-height: 100px;
  background-color:rgba(0, 0, 0, 1);
  height: auto;
}
