.container{
    height: auto;
    display: flex;
    flex-direction: column;
    font-size: 0.75px;
    width: 100%;
    box-sizing: border-box;
}
.contNav{
    height: 75px;
    background-color: black;
}
.contTitle{
    box-sizing: border-box;
    font-size: 45px;
    margin-left: 55px;
    margin-top: 23px;
}
.container0{
    box-sizing: border-box;
    height: auto;
    min-height: 100vh;
    display: flex;
    padding-right: 57px;
    padding-left: 57px;
    margin-top: 23px;
    padding-bottom: 38px;
    width: 100%;
}
.container2{
    box-sizing: border-box;
    width: 50%;
    //background-color: red;
    padding-right: 38px;
  
  }
  .container3{
    box-sizing: border-box;
      width: 50%;
      display: flex;
      flex-direction: column;
      margin-left: 38px;
  }
  .contName{
      display: flex;
      //flex: 1;
  }
  .contName input{
      width: 50%;
      border-bottom: 1px solid #eee;
      padding-bottom: 15px;
  }
  .container2 h1{
      font-size: 38px;
      margin-bottom: 8px;
      color: #683db8;
  }
  .container2 p{
    font-size: 20em;
    font-family: 'Open Sans', sans-serif;
    margin-bottom: 15px;

}
.container2 h4{
    font-weight: 500;
    font-size: 18px;
    margin-top: 15px;
}
.container3 input{
    font-size: 20em;
    border-bottom: 1px solid #adadad;
    padding-bottom: 15px;
    margin-top: 15px;
    margin-bottom: 38px;
    margin-right: 30px;
}
.container3 h5{
    width: 188px;
    font-size: 20em;
    background-color: black;
    color: white;
    text-align: center;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-top: 53px;
}
.container3 h5:hover{
    cursor: pointer;
    background-color: #683db8;
}
.messageInput{
    width: 100%;
    min-height: 150px;
    height: auto;
    font-size: 15px;
    border: none;
    flex: 1;  
    padding: 15px;
    outline: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    resize: none;
    //background-color: red;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
}
.bookingContMeso{
    box-sizing: border-box;
    height: auto;
    min-height: 150px;
    border: 1px solid #adadad;
    overflow: hidden;
    padding-bottom: 8px;
    margin-top: 38px;
    margin-right: 30px;
    
   
}
.container2 h3{
  font-size: 20px;
  margin-top: 30px;
}
.footerIcons{
    margin-top: 23px;
    font-size: 30px;
    margin-right: 20px;
    color: #683db8;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.05);
    padding: 8px;
    border-radius: 8px;
}
.footerIcons:hover{
    cursor: pointer;
    transform: scale(1.2);
}
.container3 H6{
    font-size: 19px;
    margin-bottom: 15px;
    color: #683db8;
}
@media all and (max-width: 1050px) {
    .container0{
        height: auto;
        min-height: 100vh;
        display: flex;
        padding-right: 38px;
        padding-left: 38px;
        //flex-direction: column;
    }
    .contTitle{
        font-size: 45px;
        margin-left: 38px;
        margin-top: 30px;
    }
    .container2 h1{
        font-size: 38px;
    }

 }
@media all and (max-width: 825px) {
    .contTitle{
        font-size: 45px;
        margin-left: 75px;
        margin-top: 30px;
    }
    .container0{
        flex-direction: column;
        padding-right: 75px;
        padding-left: 75px;
    }
    .container2{
        width: 100%;
        padding-right: 30px;
       
    }
    .container3{
        width: 100%;
        margin-top: 53px;
        margin-left: 0px;
        //background-color: red;
    }
    .socialCont{
        display: none;
    }
    .container2 h3{
        display: none;
      }
      .container3 H6{
          font-size: 19px;
          margin-bottom: 15px;
          color: #683db8;
      }

 }

 @media all and (max-width: 600px) {
    .container3{
        margin-top: 38px;
    }
    .container0{
        padding-right: 38px;
        padding-left: 38px;
        margin-top: 15px;
    }
    .container2 h1{
        font-size: 30px;
    }
    .contTitle{
        font-size: 38px;
        margin-left: 38px;
        margin-top: 23px;
    }
    .container2 h4{
        font-size: 25em;
        margin-top: 15px;
    }
    .bookingContMeso{
        margin-right: 0px;
    }
    .container3 input{
        margin-right: 0px;
    }
 }
 @media all and (max-width: 450px) {
    .container3{
        margin-top: 23px;
    }
    .container3 H6{
        font-size: 15px;
    }
    .container2{
        width: 100%;
        padding-right: 0px;
    }
    .container0{
        padding-right: 23px;
        padding-left: 23px;
        margin-top: 8px;
    }
    .contTitle{
        font-size: 30px;
        margin-left: 23px;
        margin-top: 23px;
    }
    .container2 h1{
        font-size: 27px;
    }
    .container2 h4{
        font-size: 20em;
        margin-top: 15px;
    }
    .container2 p{
        font-size: 18em;
    }
    .contName{
        display: flex;
        flex-direction: column;
        //flex: 1;
    }
    .contName input{
        width: 100%;
    }
    .contNav{
        height: 75px;
    }

 }
 @media all and (max-width: 375px) {
    .container3 input{
        font-size: 18em;
        padding-bottom: 15px;
        margin-top: 15px;
        margin-bottom: 15px;
    }
    .messageInput{
        font-size: 14px;
        padding: 8px;
    }
    .bookingContMeso{
        margin-top: 23px; 
    }
    .container0{
        padding-right: 15px;
        padding-left: 15px;
    }
    .contTitle{
        font-size: 23px;
        margin-left: 15px;
        margin-top: 25px;
    }
    .container2 h1{
        font-size: 19px;
    }
    .container3 h5{
        width: 150px;
        font-size: 18em;
        padding-top: 15px;
        padding-bottom: 15px;
        margin-top: 23px;
    }

 }
 @media all and (max-width: 300px) {
    .container0{
        padding-right: 8px;
        padding-left: 8px;
    }
    .contTitle{
        font-size: 23px;
        margin-left: 8px;
        margin-top: 8px;
    }
    .container2 h1{
        font-size: 19px;
    }
    .container2 h4{
        font-size: 18em;
        margin-top: 15px;
    }
    .container2 p{
        font-size: 16em;
    }
   

 }