
  //shops reel
  $primaryColor: #eb3e32;
  $secondaryColor: #FED800;
  .boduTitleMainCont{
    min-height:580px;
    height: 90vh;
    width:100%;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
    z-index: 1;
    //box-shadow: 0 3px 6px 0 rgba(0,0,0,0.05);
    
  }
  .titleDivCont{
    min-height:580px;
    height: 90vh;
    width:100%;
     overflow:hidden;
  }
 
#titleH4{
  color: white;
  font-size: 50px;
  font-weight: 400;
  margin-top: -100px;
  font-family: 'Lato', sans-serif;
  margin-left: 0px;
  margin-bottom: 0px;
  font-style: italic;
}
.detailsP{
  color: white;
  font-size: 80px;
  //background-color:red;
  font-weight: 900;
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: 'Lato', sans-serif;
  font-style: italic;
  letter-spacing: 2px;
  //text-align: center;
}
.det2{
  margin-top: 20px;
  margin-bottom: 0px;
  font-family: 'Lato', sans-serif;
  font-style: italic;
}
.det3{
font-size: 12px;
color: #fff;
margin-top: 30px;
margin-bottom: 5px;
}
.emailDiv{
  background-color:rgb(63, 25, 124);
  display: flex;
  width:250px;
  justify-content:center;
  border-radius: 5px;
  height: 40px;
  align-items:center;
  margin-top: 5px;
}
#email1{
  box-sizing: border-box;
  padding-left: 10px;
  color:#000;
  font-weight: 400;
  border:none;
  border-style: none;
  outline-style: none;
  //background-color:red;
  overflow: hidden;
}
.email2{
  padding-right: 10px;
  height: 100%;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content:center;
  font-family: 'Lato', sans-serif;
  color:white;
  text-decoration: none;
}
.email2:hover{
  cursor: pointer;
  
}

.aroowsDiv{
  position: absolute;
  margin-top: 500px;
  display: flex;
  margin-left: 50%;
  align-items: center;
  z-index: 999;
}
.titleCont{
  height:100%;
  position:absolute;
  display:flex;
  flex-direction:column;
  width:100%;
  background-color: #00000055;
  //position: relative;
}
.titleCont2{
  height:100%;
  display: flex;
  flex-direction: column;
  padding-left: 50px;
  justify-content: center;
}
.emDiv{
  position: absolute;
  bottom:0;left:0;
  z-index: 1;
  margin-bottom:100px;
  margin-left: 50px;
}
.theImage{
  width: 100%;
  height: 100%;
  object-fit: cover;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.86), rgba(0, 0, 0, 0.86))
}
.backFang{
  font-size: 20px;
  color: #fff;
  background-color:  #FB8F00;
  border-radius: 50px;
  padding:5px;
  
}
.frontFang{
  font-size: 20px;
  color: #fff;
  background-color: #FB8F00;
  border-radius: 50px;
  margin-left: 10px;
  padding:5px
}
@media all and (max-width: 1200px) {
  #titleH4{
    margin-top: -100px;
  }
}
@media all and (max-width: 1000px) {
  #titleH4{
    //margin-top: 2%;
  }
  .detailsP{
    font-size: 70px;
  }
}
@media all and (max-width: 769px) {
  .boduTitleMainCont{
    min-height:500px;
    height: 60vh;
    
  }
  .aroowsDiv{
    margin-top: 350px;
  }
  #titleH4{
    font-size: 45px;
   //margin-top: 2.5%;
  }
  .detailsP{
    font-size: 50px;
    
  }
  .titleDivCont{
    min-height:500px;
    height: 60vh;
    
  }
  .emDiv{
    margin-bottom: 70px;
  }
}
@media all and (max-width: 615px) {

  #titleH4{
    font-size: 40px;
    //margin-top: 3%;
  }
  .detailsP{
    font-size: 45px;
  }
  /*.boduTitleMainCont{
    min-height:400px;
    height: 45vh;
  }
  .titleDivCont{
    min-height:400px;
    height: 45vh;
  }*/

  .aroowsDiv{
  margin-top: 350px;
   
  }
  .titleCont2{
    padding-left: 30px;
  }
  .emDiv{
    margin-left: 30px;
  }
}
@media all and (max-width: 500px) {

  #titleH4{
   // margin-top: 4%;
  }}
  @media all and (max-width: 420px) {
    .boduTitleMainCont{
      min-height:400px;
      height: 40vh;
      box-shadow: 3px 3px 6px 0 rgba(0,0,0,0.1);
      padding-bottom: 2px;
    }
    .titleDivCont{
      min-height:400px;
      height: 40vh;
    }
   
    .aroowsDiv{
 
      margin-top: 330px;
     
    }
    .emDiv{
      margin-bottom: 50px;
    }

}
@media all and (max-width: 420px) {
  .detailsP{
    font-size: 35px;
  }
  #titleH4{
    font-size: 30px;
    margin-top:-50px;
  }
  .det3{
    font-size: 10px;
    }
    .emailDiv{
      width:170px;
      border-radius: 5px;
      height: 30px;
      margin-top: 5px;
    }
    #email1{
      padding-left: 10px;
      color:#000;
      font-weight: 400;
      font-size: 12px;
      border:none;
      border-style: none;
      outline-style: none;
    }
    .email2{
      padding-right: 0px;
      font-weight: 600;
      display: flex;
      font-size: 10px;
    }
    .titleCont2{
      padding-left: 20px;
      justify-content: center;
    }
    .emDiv{
      margin-left: 20px;
    }
}
  @media all and (max-width: 384px) {
    #titleH4{
      font-size: 25px;
      //margin-top: 3%;
    }
    .detailsP{
      font-size: 30px;
    }
    .boduTitleMainCont{
      min-height:350px;
      height: 50vh;
      box-shadow: 3px 3px 6px 0 rgba(0,0,0,0.1);
      padding-top: 2px;
      padding-bottom: 2px;
    }
    .titleDivCont{
      min-height:350px;
      height: 50vh;
    }
    
    .aroowsDiv{
      width: 98%;
      margin-top: 120px;
      margin-left: 3px;
      margin-right: 10px;
      justify-content: space-between;
      display: flex;
    }
    
    .theImage{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .backFang{
      font-size: 20px;
     
    }
    .frontFang{
      font-size: 20px;
      
    }
}

@media all and (max-width: 307px) {
 
  #titleH4{
    font-size: 20px;
    margin-top:-50px;
  }
  .detailsP{
    font-size: 25px;
  }
  
  .aroowsDiv{
 
    margin-top: 183px;
   
  }
}

