.container{
  height: auto;
  background-color: whitesmoke;
  position: relative;
  width: 100%;
  overflow: hidden;
  
}
.reelCont{
 margin-top: 0px;

}
.vidCont{
  box-sizing: border-box;
  padding:20px 70px 10px 70px;
}
.vidCont h1{
  margin: 0px;
  margin-top: 15px;
  font-size: 30px;
  margin-right: 30px;
 
}
@media all and (max-width: 1200px) {
  .vidCont{
    box-sizing: border-box;
    padding:20px 50px 10px 50px;
  }
}
@media all and (max-width: 500px) {
  .vidCont{
    box-sizing: border-box;
    padding:10px 20px 10px 20px;
  }
  .vidCont h1{
    margin-top: 10px;
    font-size: 25px;
    margin-right: 30px;
    line-height: 20px;
   
  }
}
@media all and (max-width: 420px) {
  .vidCont{
    box-sizing: border-box;
    padding:10px 10px 10px 10px;
  }

}
//radio love, wangu, tesa, maombi, million dollar, jipe,